body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333 !important;
}

.shadow {
  -webkit-box-shadow: 0px 0px 31px 22px rgba(20, 20, 20, 0.72);
  -moz-box-shadow: 0px 0px 31px 22px rgba(20, 20, 20, 0.72);
  box-shadow: 0px 0px 31px 22px rgba(20, 20, 20, 0.72);
}

.desktop,
.mobile {
  width: 100%;
}

@media only screen and (max-width: 520px) {
  .desktop {
    display: none;
  }
}

@media only screen and (min-width: 521px) {
  .mobile {
    display: none;
  }
}
